<template>
  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
    <div
      class="card h-100"
      :class="{
        draft: props.card.status == 'draft' || props.card.status == 'banned',
      }"
    >
      <img
        v-if="store.state.userPreference.showBookFace == true"
        :src="bookThumbnailUrl"
        class="card-img-top"
        alt="..."
        :id="'card-' + props.card.id"
        @click="openModal"
      />
      <div
        v-if="props.card.recommended"
        class="card-img-overlay recommended-large"
        @click="openModal"
      >
        <svg fill="currentColor">
          <use xlink:href="/icon/icon-recommended-large.svg#icon" />
        </svg>
      </div>
      <div class="card-body p-0">
        <h5 class="card-title m-3">
          {{ props.card.bookTitle }}
        </h5>
        <p class="card-text m-3">
          {{ props.card.bookAuthors }}<br />{{ props.card.bookPublisher
          }}{{ props.card.bookPublishYear ? "・" + props.card.bookPublishYear : "" }}
        </p>

        <p class="card-text m-3">
          {{
            props.card.reviewTyped?.length > 100
              ? props.card.reviewTyped.slice(0, 100) + "..."
              : props.card.reviewTyped
          }}
        </p>
        <img class="img-fluid" v-if="imageUrl" :src="imageUrl" />
      </div>
      <div class="card-footer p-0 mx-3 text-end" @click="openModal">
        <p class="card-text m-0 mt-3">
          {{
            getDateString(props.card.createdAtSecond, store.state.userPreference.hiragana)
          }}
        </p>
        <p class="card-text m-0" v-if="reviewer.user?.displayName">
          {{ reviewer.user.className }}
          <span v-if="reviewer.user.className">・</span>
          {{ reviewer.user.displayName }}
        </p>
        <p class="card-text mt-0" v-else>
          {{ reviewer.school?.city }}
          <span v-if="reviewer.school?.city">・</span>
          {{ reviewer.school?.schoolName }}
          <span v-if="typeof reviewer.schoolYear !== 'undefined'">
            <br />
            {{ reviewer.schoolYear }}年生
          </span>
        </p>
      </div>
      <div class="d-flex justify-content-between mb-3 mt-3 ms-3 me-3">
        <LikeButton :card="props.card" />
        <Medals :levels="reviewer.medalLevel" />
      </div>
      <div v-if="manylikes" class="manylikes">
        <img src="/icon/manylike.png" width="66" height="45" />
      </div>
      <!-- <div>
        {{ reviewer.cardNum }}
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, computed, onMounted } from "vue";
import { useStore } from "@/store/store";

import LikeButton from "./LikeButton.vue";
import Medals from "./Medals.vue";
import { getDateString, convertBookThumbnailURL } from "../utilities/misc";
import { Card } from "@/domain/Card";
import { getImageDataUrlFromJson, getFiscalYear } from "@/utilities/misc";
import { getSchool } from "@/infrastructure/SchoolRepository";
import { getUser } from "@/infrastructure/UserRepository";
import { getCardNum } from "@/infrastructure/CardNumRepository";
import { User } from "@/domain/User";
import { School } from "@/domain/School";
import { CardNum } from "@/domain/CardNum";
import { medalRequirement } from "@/domain/medalRequirement";
import { setting } from "@/setting";

export default defineComponent({
  name: "CardItem",

  components: { LikeButton, Medals },

  props: {
    card: {
      type: Object as PropType<Card>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();

    const imageUrl = computed(() =>
      getImageDataUrlFromJson(props.card.reviewHandwrittenJson, "imgcanvas")
    );

    const reviewer = reactive<{
      user?: User;
      school?: School;
      schoolYear?: number;
      cardNum?: CardNum;
      medalLevel?: number[];
    }>({});

    const setReviewerInfo = async () => {
      // if card reviewer's school is the same as user, load user infomation
      if (
        props.card.schoolID == store.state.user?.schoolID &&
        store.state.schoolSettings.hideNameAndClass == false
      ) {
        reviewer.user = await getUser(props.card.userID);
      } else {
        reviewer.school = await getSchool(props.card.schoolID);
      }
      if (props.card.reviewerAdmissionYear !== 0) {
        reviewer.schoolYear =
          getFiscalYear(props.card.createdAtSecond) -
          props.card.reviewerAdmissionYear +
          1;
      }
    };

    onMounted(async () => {
      setReviewerInfo();
      reviewer.cardNum = await getCardNum(props.card.userID);
      if (typeof reviewer.cardNum !== "undefined") {
        reviewer.medalLevel = [2, 1, 0].map((monthBefore) =>
          medalRequirement(reviewer.cardNum?.getNumberOfPreviousMonth(monthBefore) ?? 0)
        );
      }
    });

    const openModal = () => {
      emit("openModal", props.card);
    };

    const bookThumbnailUrl = convertBookThumbnailURL(
      props.card.bookThumbnailUrl,
      props.card.createdAtSecond
    );

    const manylikes = computed(() => props.card.numLiked >= setting.manylikeRequirement);

    return {
      store,
      props,
      imageUrl,
      reviewer,
      bookThumbnailUrl,
      manylikes,
      openModal,
      getDateString,
    };
  },
});
</script>

<style>
.draft {
  opacity: 0.3;
  display: block;
}
</style>
