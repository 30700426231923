import { createApp } from "vue";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import { initializeFirebase } from "@/infrastructure/firebase";

import store, { key } from "@/store/store";
import { router } from "@/router";

import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./css/custom.scss";
import "bootstrap-icons/font/bootstrap-icons.css";

import App from "./App.vue";

initializeFirebase();

const app = createApp(App);

import pkg from "../package.json";

const version = pkg.version;

if (window.location.hostname !== "localhost") {
  Sentry.init({
    app,
    dsn: "https://41493b33347f42e09b10f7b4e11ddb54@o1130341.ingest.sentry.io/6174326",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["yomumo.net", "https://book-card-dev.web.app/", /^\//],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    release: `yomumo@${version}-${process.env.VITE_GIT_COMMIT_HASH}`,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["Load failed"]
  });
}

store.dispatch("startAuth");

app.use(router).use(store, key).mount("#app");
