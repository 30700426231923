import { collection, getFirestore } from "@firebase/firestore";
import {
  FirestoreDataConverter,
  doc,
  getDoc,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import { CardNum } from "@/domain/CardNum";
import { User } from "@/domain/User";

type docDataTypeFromFirebase = Partial<
  CardNum & {
    lastUpdatedAt: { seconds: number; nanosecondes: number };
  }
>;

export const cardNumConverter: FirestoreDataConverter<CardNum> = {
  toFirestore(/* cardNum: CardNum */) {
    throw new Error("CardNum document can be writtin only from admin SDK.");
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options) as docDataTypeFromFirebase;

    // Check important parameteres
    if (
      typeof data.numAll === "undefined" ||
      typeof data.lastUpdatedAt === "undefined" ||
      typeof data.lastUpdatedAt?.seconds === "undefined"
    ) {
      throw new Error(`CardNum data is not properly defined ${data}`);
    }
    const cardNum = new CardNum(data.numAll, data.lastUpdatedAt.seconds);

    Object.keys(data).forEach((key) => {
      if (key.match(/^num([0-9]{4}|[0-9]{6})$/)) cardNum[key] = data[key];
    });

    return cardNum;
  },
};

export const getCardNum = async (id: string): Promise<CardNum> => {
  const ref = doc(getFirestore(), "Users", id, "CardNum/cardNum").withConverter(
    cardNumConverter
  );
  try {
    const snapshot = await getDoc(ref);
    if (!snapshot.exists())
      throw new Error(`Document for ${id} does not exist`);

    return snapshot.data();
  } catch (e) {
    console.error(e);
  }
  return new CardNum();
};

export const getSchoolYearCardNum = async (
  user: User,
  schoolYear: number
): Promise<number> => {
  const admissionYear = user.admissionYear + user.schoolYear - schoolYear-1;
  console.log({userAdmissionYear: user.admissionYear, userSchoolYear: user.schoolYear, schoolYear, admissionYear, schoolID: user.schoolID, })
  const ref = collection(getFirestore(), "SchoolYearClasses");

  const snapshot = await getDocs(
      query(
        ref,
        where("schoolID", "==", user.schoolID),
        where("admissionYear", "==", admissionYear),
        where("schoolYear", "==", schoolYear)
      )
    );
    const sum = snapshot.docs.reduce((prev, current) => {
      const doc = current.data();
      const num = doc.numCards ?? 0;
      return (prev += num);
    }, 0);
    console.log(snapshot.docs[0].data());
    return sum;
};
